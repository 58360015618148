import { useRef } from 'react';
import {
  TrackReference,
  TrackReferenceOrPlaceholder,
} from '@livekit/components-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { NameTag } from '../../../DynamicLayout/StreamingView';
import { ParticipantTileMinimal } from '../../../LiveKit/ParticipantTileMinimal';
import { useSize } from '../../../LiveKit/useResizeObserver';
import { StreamType } from '../../enums';
import { VideoContainer } from '../../Styled';

interface IMainTrack extends React.HTMLAttributes<HTMLMediaElement> {
  mainTrack: TrackReferenceOrPlaceholder;
  streamType: StreamType;
}

export const MainTrackContainer = styled(VideoContainer)({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',

  '& .lk-participant-media-video[data-lk-orientation=landscape]': {
    objectFit: 'contain!important',
  },
});

export const MainTrack = ({ mainTrack, streamType, ...props }: IMainTrack) => {
  const ref = useRef(null);
  const { height, width } = useSize(ref);

  const sx =
    width / height > 16 / 9
      ? { height: '100%' }
      : {
          width: '100%',
          height: streamType !== StreamType.Webcam ? '100%' : 'unset',
        };

  return (
    <MainTrackContainer ref={ref} {...props}>
      <Box sx={{ position: 'relative', ...sx }}>
        <ParticipantTileMinimal
          className={streamType === StreamType.Webcam ? 'videoCam' : undefined}
          trackRef={mainTrack as TrackReference}
        />
        {streamType === StreamType.Webcam ? (
          <NameTag participant={mainTrack.participant} />
        ) : null}
      </Box>
    </MainTrackContainer>
  );
};
